import React, { useState, useEffect, useCallback } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useMemo } from 'react';
import debounce from 'lodash/debounce';
import {
  Box, Text, Button, Spinner, Dialog, PageHeader,
  FormControl, TextInput, ActionMenu, Tooltip, IconButton,
  Truncate, Label, ActionList,
} from '@primer/react'
import { Table } from '@primer/react/drafts'
import { SearchIcon } from '@primer/octicons-react'
// import { PauseIcon, PlayIcon } from '@primer/octicons-react'
import { DataTable } from '@primer/react/drafts'
import { Hidden } from '@primer/react/drafts'
import { toast } from 'react-hot-toast'
import { CustomToast } from './custom-toast'
import { Icons } from '@/components/icons'
import { GroupByMenu } from '@/components/group-by-devices'
import { EditGroupButton } from '@/components/select-panels'
import { useReloadTable } from '@/lib/devices-reload-context'
import {
  getDevices, enrollDevice, getDeviceGroup, deleteDevice,
  getPlaylists, getSchedules
} from '@/lib/calls'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const SortButton = ({ onClick, sortKey, label, currentSort }) => (
  <Button
    variant="invisible"
    onClick={() => onClick(sortKey)}
    sx={{
      display: 'flex',
      alignItems: 'center',
      color: currentSort.key === sortKey ? 'accent.fg' : 'fg.default',
    }}
  >
    {label}
    {currentSort.key === sortKey && (
      <Icons.ArrowUp
        sx={{
          ml: 1,
          transform: currentSort.direction === 'desc' ? 'rotate(180deg)' : 'none',
        }}
      />
    )}
  </Button>
)

const useDevices = (groupId) => {
  const [devices, setDevices] = useState([])
  const [filteredDevices, setFilteredDevices] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [groupData, setGroupData] = useState(null)
  const [groupName, setGroupName] = useState(null)
  const [devicesCount, setDevicesCount] = useState(0)
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'desc' })
  const [query, setQuery] = useState('');

  const fetchDevices = useCallback(async () => {
    setIsLoading(true)
    setError(null)
    try {
      let response
      if (groupId) {
        response = await getDeviceGroup(groupId)
        setDevices(response.data.data.devices)
        setGroupData(response.data.data.devices.map(device => ({ text: device.name, id: device.id })))
        setGroupName(response.data.data.name)
        setDevicesCount(response.data.data.devices.length)
      } else {
        response = await getDevices()
        setDevices(response.data)
        setGroupName(null)
        setGroupData(null)
        setDevicesCount(0)
      }
    } catch (err) {
      setError(err.response?.status === 403 ? { restricted: true } : err)
    } finally {
      setIsLoading(false)
    }
  }, [groupId])

  const sortDevices = useCallback((devicesToSort, config = sortConfig) => {
    return [...devicesToSort].sort((a, b) => {
      if (a[config.key] < b[config.key]) {
        return config.direction === 'asc' ? -1 : 1
      }
      if (a[config.key] > b[config.key]) {
        return config.direction === 'asc' ? 1 : -1
      }
      return 0
    })
  }, [sortConfig])

  const debouncedFilterAndSort = useMemo(
    () =>
      debounce((queryString, devices, sortConfig) => {
        if (queryString === '') {
          setFilteredDevices(sortDevices(devices, sortConfig));
        } else {
          const queryTerms = queryString.split(/\s+/);
          const filteredResults = queryTerms
            .map(term =>
              devices
                .map(device => {
                  if (device.name.toLowerCase().startsWith(term)) return { priority: 1, device };
                  else if (device.name.toLowerCase().includes(term)) return { priority: 2, device };
                  else return { priority: -1, device };
                })
                .filter(result => result.priority > 0)
                .map(result => result.device)
            )
            .flat()
            .filter((value, index, self) => self.findIndex(v => v.id === value.id) === index);
          setFilteredDevices(sortDevices(filteredResults, sortConfig));
        }
      }, 300),
    [sortDevices]
  );

  const filterAndSortDevices = useCallback(
    (newQuery, config = sortConfig) => {
      setQuery(newQuery);
      debouncedFilterAndSort(newQuery.toLowerCase().trim(), devices, config);
    },
    [devices, sortConfig, debouncedFilterAndSort]
  );

  const handleSort = useCallback((key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }))
  }, [])

  useEffect(() => {
    filterAndSortDevices('', sortConfig)
  }, [devices, sortConfig, filterAndSortDevices])

  return {
    devices: filteredDevices,
    isLoading,
    error,
    fetchDevices,
    filterAndSortDevices,
    handleSort,
    sortConfig,
    groupData,
    groupName,
    devicesCount,
    query,
  };
}

const useDialog = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState)
  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)
  return [isOpen, open, close]
}

const useSchedulesAndPlaylists = () => {
  const [schedules, setSchedules] = useState([])
  const [playlists, setPlaylists] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const [schedulesResponse, playlistsResponse] = await Promise.all([
          getSchedules(),
          getPlaylists()
        ])
        setSchedules(schedulesResponse.data)
        setPlaylists(playlistsResponse.data)
      } catch (err) {
        setError(err)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [])

  return { schedules, playlists, isLoading, error }
}

const DeviceTable = ({ devices, onDelete, onSort, sortConfig }) => {
  const [pageIndex, setPageIndex] = useState(0)
  const pageSize = 10
  return (
    <Table.Container>
      <DataTable
        data={devices.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)}
        columns={[
          {
            header: 'Status',
            field: 'last_seen',
            renderCell: row => {
              const isOnline = new Date() - new Date(row.last_seen) < 2 * 60 * 1000
              return (
       
                  <>
                              <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          width: '8px',
                                          height: '8px',
                                          borderRadius: '50%',
                                          backgroundColor: isOnline
                                            ? 'success.default'
                                            : 'danger.default',
                                        }}
                                      ></Box>
                                      <Text>
                                        {isOnline ? (
                                          // <Label variant="success">Online</Label>
                                          <Box
                                            sx={{
                                              backgroundColor: 'success.fg',
                                              borderColor: 'success.fg',
                                              width: 14,
                                              height: 14,
                                              borderRadius: 10,
                                              margin: 'auto',
                                              borderWidth: '1px',
                                              borderStyle: 'solid',
                                            }}
                                          />
                                        ) : (
                                          <Box
                                            sx={{
                                              backgroundColor: 'danger.fg',
                                              borderColor: 'danger.fg',
                                              width: 14,
                                              height: 14,
                                              borderRadius: 10,
                                              margin: 'auto',
                                              borderWidth: '1px',
                                              borderStyle: 'solid',
                                            }}
                                          />
                                        )}
                                      </Text>
                                    </Box>

</>
              )
            },
          },
          {
            header: () => <SortButton onClick={onSort} sortKey="name" label="Name" currentSort={sortConfig} />,
            field: 'name',
            renderCell: row => (
              <Tooltip aria-label={row.name}>
                <Link to={`/dashboard/devices/${row.id}`}>
                  <Button variant='invisible'>
                    <Truncate>{row.name}</Truncate>
                  </Button>
                </Link>
              </Tooltip>
            ),
          },
          {
            header: () => <SortButton onClick={onSort} sortKey="device_manufacturer" label="Manufacturer" currentSort={sortConfig} />,
            field: 'device_manufacturer',
            renderCell: row => (
              <Tooltip aria-label={row.device_manufacturer}>
                <Truncate>{row.device_manufacturer}</Truncate>
              </Tooltip>
            ),
          },
          {
            header: 'Screen resolution',
            field: 'screen_resolution',
            renderCell: row => `${row.device_screen_width}x${row.device_screen_height}`,
          },
          {
            header: () => <SortButton onClick={onSort} sortKey="app_version" label="App version" currentSort={sortConfig} />,
            field: 'app_version',
          },
          {
            header: 'Actions',
            field: 'actions',
            renderCell: row => (
              <ActionMenu>
                <ActionMenu.Anchor>
                  <IconButton variant='invisible' icon={Icons.Menu} aria-label='Menu' />
                </ActionMenu.Anchor>
                <ActionMenu.Overlay width='medium'>
                  <ActionList>
                    <Link to={`/dashboard/devices/${row.id}`}>
                      <ActionList.Item>View device</ActionList.Item>
                    </Link>
                    <ActionList.Divider />
                    <ActionList.Item variant='danger' onSelect={() => onDelete(row.id)}>
                      Delete device
                    </ActionList.Item>
                  </ActionList>
                </ActionMenu.Overlay>
              </ActionMenu>
            ),
          },
        ]}
      />
      <Table.Pagination
        aria-label="Pagination for Devices"
        pageSize={pageSize}
        totalCount={devices.length}
        pageIndex={pageIndex}
        onChange={({ pageIndex }) => setPageIndex(pageIndex)}
      />
    </Table.Container>
  )
}

const EnrollDeviceDialog = ({ isOpen, onClose, onSubmit }) => {
  const [nameValue, setNameValue] = useState('')
  const [codeValue, setCodeValue] = useState('')
  const [validationResult, setValidationResult] = useState(null)
  const [codeValidationResult, setCodeValidationResult] = useState(null)

  const handleInputChange = (event) => {
    const { value, name } = event.target
    if (name === 'name') {
      setNameValue(value)
      setValidationResult(value.length === 0 ? 'empty' : value.length > 255 ? 'tooLong' : value.length < 3 ? 'tooShort' : 'validName')
    } else if (name === 'code') {
      setCodeValue(value)
      setCodeValidationResult(value.length === 0 ? 'empty' : value.length > 6 ? 'tooLong' : value.length < 6 ? 'tooShort' : 'validCode')
    }
  }

  return (
    <Dialog isOpen={isOpen} onDismiss={onClose} aria-labelledby='dialog-label'>
      <Dialog.Header>Enroll a new device</Dialog.Header>
      <Box p={3}>
        <Text id='dialog-label' color='fg.muted' fontSize={1}>
          You will be billed for devices added. Deleting the device afterwards won't reduce your billing until next billing cycle.
          Continuing represents your agreement to be billed for this device. Enter the name and the 6 characters code that from the TV/device screen.
        </Text>
        <Box mt={3}>
          <FormControl required id='name'>
            <FormControl.Label htmlFor='name'>Name</FormControl.Label>
            <TextInput width='100%' value={nameValue} onChange={handleInputChange} name='name' placeholder='Name' />
            {validationResult && <FormControl.Validation variant={validationResult === 'validName' ? 'success' : 'error'}>
              {validationResult === 'empty' ? 'Name cannot be empty' :
               validationResult === 'tooLong' ? 'Name cannot be longer than 255 characters' :
               validationResult === 'tooShort' ? 'Name cannot be less than 3 characters' : 'Valid name'}
            </FormControl.Validation>}
          </FormControl>
          <FormControl required id='code'>
            <FormControl.Label htmlFor='code'>Code</FormControl.Label>
            <TextInput width='100%' value={codeValue} onChange={handleInputChange} name='code' placeholder='Enrollment code' />
            {codeValidationResult && <FormControl.Validation variant={codeValidationResult === 'validCode' ? 'success' : 'error'}>
              {codeValidationResult === 'empty' ? 'Code cannot be empty' :
               codeValidationResult === 'tooLong' ? 'Code cannot be longer than 6 characters' :
               codeValidationResult === 'tooShort' ? 'Code cannot be less than 6 characters' : 'Valid code'}
            </FormControl.Validation>}
          </FormControl>
        </Box>
        <Box display='flex' mt={3} justifyContent='flex-end'>
          <Button onClick={onClose} sx={{ mr: 1 }} variant='invisible'>Cancel</Button>
          <Button variant='primary' onClick={() => onSubmit(nameValue, codeValue)} disabled={validationResult !== 'validName' || codeValidationResult !== 'validCode'}>
            Submit
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

const DeleteDeviceDialog = ({ isOpen, onClose, onConfirm }) => (
  <Dialog isOpen={isOpen} onDismiss={onClose} aria-labelledby='dialog-label'>
    <Dialog.Header>Delete device</Dialog.Header>
    <Box p={3}>
      <Icons.Alert sx={{ mr: 2, color: 'danger.fg' }} />
      <Text id='dialog-label' color='danger.fg' fontSize={1}>
        This action can't be undone. Are you sure you want to delete this device? This device will erase app storage and show a new
        enrollment code on screen. Billing will be adjusted accordingly for the next month's billing cycle.
      </Text>
      <Box display='flex' mt={3} justifyContent='flex-end'>
        <Button onClick={onClose} sx={{ mr: 1 }} variant='invisible'>Cancel</Button>
        <Button variant='danger' onClick={onConfirm}>Delete device</Button>
      </Box>
    </Box>
  </Dialog>
)



export function DevicesPage() {
  const queryParams = useQuery()
  const groupId = queryParams.get('device_group_id')
  const handleFilterChange = (e) => {
    const newQuery = e.target.value;
    setFilterText(newQuery);
    filterAndSortDevices(newQuery, sortConfig);
  };
  const { 
    devices, 
    isLoading, 
    error, 
    fetchDevices, 
    filterAndSortDevices, 
    handleSort,
    sortConfig,
    groupData, 
    groupName, 
    devicesCount 
  } = useDevices(groupId)
  const { schedules, playlists } = useSchedulesAndPlaylists()
  const [isEnrollDialogOpen, openEnrollDialog, closeEnrollDialog] = useDialog()
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useDialog()
  const [deviceIdToDelete, setDeviceIdToDelete] = useState(null)
  const { reloadTable, setReloadTable } = useReloadTable()
  const [filterText, setFilterText] = useState('')
  const [isPolling, setIsPolling] = useState(false);
  const PollingToggle = ({ isPolling, setIsPolling }) => (
    <>
    <Hidden when={['narrow']}>
      <Button
        onClick={() => setIsPolling(!isPolling)}
        variant="invisible"
      >
        {isPolling ? 'Pause' : 'Resume'} auto-refresh
      </Button>
    </Hidden>
<Hidden when={['regular', 'wide']}>
    <Button
      onClick={() => setIsPolling(!isPolling)}
      variant="invisible"
    >
      {isPolling ? 'Pause' : 'Resume'}
    </Button>
</Hidden>
    </>
  );

  useEffect(() => {
    let intervalId;
  
    if (isPolling) {
      intervalId = setInterval(() => {
        fetchDevices();
      }, 15000); // 15 seconds
    }
  
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isPolling, fetchDevices]);

  useEffect(() => {
    fetchDevices()
  }, [fetchDevices, reloadTable])

  useEffect(() => {
    filterAndSortDevices(filterText, sortConfig)
  }, [filterText, filterAndSortDevices, sortConfig])

  const handleDeleteDevice = useCallback(async () => {
    try {
      await deleteDevice(deviceIdToDelete)
      setReloadTable(prev => !prev)
      closeDeleteDialog()
      toast.custom(t => <CustomToast message='Device deleted successfully' type='success' />)
    } catch (error) {
      toast.custom(t => <CustomToast message='Error could not delete device' type='error' />)
      console.error('Error deleting device:', error)
    } finally {
      setDeviceIdToDelete(null)
    }
  }, [deviceIdToDelete, setReloadTable, closeDeleteDialog])

  const handleEnrollDevice = useCallback(async (name, code) => {
    try {
      await enrollDevice(name, code)
      setReloadTable(prev => !prev)
      closeEnrollDialog()
      toast.custom(t => <CustomToast message='Device enrolled successfully' type='success' />)
    } catch (error) {
      if (error.response?.status === 500 && error.response.data.detailed_msg === 'active subscription required for additional devices') {
        toast.custom(t => <CustomToast message='Subscription required' type='error' />)
      } else {
        toast.custom(t => <CustomToast message='Error could not enroll device' type='error' />)
      }
      console.error('Error enrolling device:', error)
    }
  }, [setReloadTable, closeEnrollDialog])

  if (error?.restricted) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3, padding: 3 }}>
        <Text sx={{ fontSize: 4, fontWeight: 'bold', textAlign: 'center' }}>
          You don't have access to this content.
        </Text>
        <Text sx={{ fontSize: 1, color: 'fg.muted' }}>
          <Icons.Alert /> Insufficient permissions to manage devices.
        </Text>
      </Box>
    )
  }
  const getSortLabel = (sortConfig) => {
    switch (sortConfig.key) {
      case 'name':
        return `Name (${sortConfig.direction === 'asc' ? 'A-Z' : 'Z-A'})`;
      // case 'device_manufacturer':
      //   return `Manufacturer (${sortConfig.direction === 'asc' ? 'A-Z' : 'Z-A'})`;
      case 'app_version':
        return `App version (${sortConfig.direction === 'asc' ? 'Low-High' : 'High-Low'})`;
      case 'created_at':
        return `Date added (${sortConfig.direction === 'asc' ? 'Oldest' : 'Newest'})`;
      case 'updated_at':
          return `Date updated (${sortConfig.direction === 'asc' ? 'Oldest' : 'Newest'})`;
      case 'last_seen':
            return `Last seen (${sortConfig.direction === 'asc' ? 'Oldest' : 'Newest'})`;
      default:
        return 'Sort';
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <PageHeader>
        <PageHeader.TitleArea>
          <PageHeader.Title as="h2">Devices</PageHeader.Title>
        </PageHeader.TitleArea>
        <PageHeader.Description sx={{ fontSize: 1, color: 'fg.muted' }}>
          Grouping devices is mandatory to continue.
        </PageHeader.Description>
        <PageHeader.Actions>
          {groupId && groupData ? (
            <EditGroupButton items={groupData} groupName={groupName} groupId={groupId} />
          ) : (
            <Button variant='primary' onClick={openEnrollDialog}>Enroll device</Button>
          )}
          <GroupByMenu name={groupName} groupId={groupId} />
          <PollingToggle isPolling={isPolling} setIsPolling={setIsPolling} />
        </PageHeader.Actions>
      </PageHeader>

      {groupId && schedules && playlists && (
        <Box sx={{ display: 'flex', flexDirection: ['column', 'row'], gap: 2, mb: 3 }}>
          <Label variant='accent' sx={{ maxWidth: '77px' }}>
            <Icons.Devices sx={{ mr: 1 }} /> 
            <Truncate>{devicesCount || '0'}</Truncate>
          </Label>
          {schedules.find(s => s.group_id === groupId) && (
            <Link to={`/dashboard/schedules?schedule_id=${schedules.find(s => s.group_id === groupId).id}`}>
              <Label variant='sponsors'>
                <Icons.Calendar sx={{ mr: 1 }} /> 
                <Truncate>{schedules.find(s => s.group_id === groupId).name}</Truncate>
              </Label>
            </Link>
          )}
          {playlists.find(p => p.playlist.id === schedules.find(s => s.group_id === groupId)?.playlist_id) && (
            <Link to={`/dashboard/playlists/editor?playlist_id=${playlists.find(p => p.playlist.id === schedules.find(s => s.group_id === groupId)?.playlist_id).playlist.id}`}>
              <Label variant='done'>
                <Icons.Play sx={{ mr: 1 }} />
                <Truncate>{playlists.find(p => p.playlist.id === schedules.find(s => s.group_id === groupId)?.playlist_id).playlist.name}</Truncate>
              </Label>
            </Link>
          )}
        </Box>
      )}

      <FormControl sx={{ width: '100%', padding: 0.5, mb: 3 }}>
        <FormControl.Label visuallyHidden>Filter devices</FormControl.Label>
        <TextInput
          leadingVisual={SearchIcon}
          placeholder='Filter devices'
          value={filterText}
          onChange={handleFilterChange}
          block
        />
      </FormControl>

      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '420px' }}>
          <Spinner />
        </Box>
      ) : devices.length === 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3 }}>
          <Text sx={{ fontSize: 4, fontWeight: 'bold' }}>
            No devices found.
          </Text>
          <Text sx={{ fontSize: 1, color: 'fg.muted' }}>
            If you haven't already deployed the app on your device, download the app from the Downloads page.
          </Text>
          <Link to='/dashboard/downloads'>
            <Button variant='primary'>Download app</Button>
          </Link>
          <Link to='/dashboard/downloads'>
            <Button variant='invisible'>Installation instructions</Button>
          </Link>
          <Link to='https://web.signgrid.io'>
            <Button variant='invisible'>Web based app for demo</Button>
          </Link>
        </Box>
      ) : (
        <>
<Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
            <ActionMenu>
              <ActionMenu.Button>
                Sort: {getSortLabel(sortConfig)}
              </ActionMenu.Button>
              <ActionMenu.Overlay>
                <ActionList>
                  <ActionList.Item onSelect={() => handleSort('name')}>
                    Name {sortConfig.key === 'name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                  </ActionList.Item>
                  {/* <ActionList.Item onSelect={() => handleSort('device_manufacturer')}>
                    Manufacturer {sortConfig.key === 'device_manufacturer' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                  </ActionList.Item> */}
                  <ActionList.Item onSelect={() => handleSort('app_version')}>
                    App version {sortConfig.key === 'app_version' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                  </ActionList.Item>
                  <ActionList.Item onSelect={() => handleSort('created_at')}>
                    Date added {sortConfig.key === 'created_at' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                  </ActionList.Item>
                  <ActionList.Item onSelect={() => handleSort('updated_at')}>
                    Date updated {sortConfig.key === 'updated_at' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                  </ActionList.Item>
                  <ActionList.Item onSelect={() => handleSort('last_seen')}>
                    Last seen {sortConfig.key === 'last_seen' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                  </ActionList.Item>
                </ActionList>
              </ActionMenu.Overlay>
            </ActionMenu>
          </Box>
          <DeviceTable 
            devices={devices} 
            onDelete={(id) => {
              setDeviceIdToDelete(id)
              openDeleteDialog()
            }}
            onSort={handleSort}
            sortConfig={sortConfig}
          />
        </>
      )}

      <EnrollDeviceDialog 
        isOpen={isEnrollDialogOpen} 
        onClose={closeEnrollDialog}
        onSubmit={handleEnrollDevice}
      />

      <DeleteDeviceDialog 
        isOpen={isDeleteDialogOpen} 
        onClose={closeDeleteDialog}
        onConfirm={handleDeleteDevice}
      />
    </Box>
  )
}