import {
  Box,
  Label,
  Text,
  Timeline,
  PageHeader,
  RelativeTime,
  IconButton,
  LinkButton,
  Button,
} from '@primer/react'
import { DataTable, Table } from '@primer/react/drafts'
import { Icons } from '@/components/icons'

export function DownloadsPage() {
  const data = [
    {
      id: '1',
      name: 'signage-1.0.8-android-api-35-rooted.apk',
      type: 'APK',
      os: 'Android 15 (API 35)',
      version: '1.0.8-android (beta)',
      arch: 'arm64',
      releasedAt: '2024-08-19T00:00:00Z',
      downloadUrl:
        'https://cdn.signgrid.io/download/signage-1.0.8-android-api-35-rooted.apk',
    },
    {
      id: '2',
      name: 'signage-1.0.8-android-api-23-rooted.apk',
      type: 'APK',
      os: 'Android 6 (API 23)',
      version: '1.0.8-android (beta)',
      arch: 'arm64',
      releasedAt: '2024-08-19T00:00:00Z',
      downloadUrl:
        'https://cdn.signgrid.io/download/signage-1.0.8-android-api-23-rooted.apk',
    },
    {
      id: '3',
      name: 'signage-1.0.7-android-api-35-rooted.apk',
      type: 'APK',
      os: 'Android 15 (API 35)',
      version: '1.0.7-android',
      arch: 'arm64',
      releasedAt: '2024-07-27T00:00:00Z',
      downloadUrl:
        'https://cdn.signgrid.io/download/signage-1.0.7-android-api-35-rooted.apk',
    },
    {
      id: '4',
      name: 'signage-1.0.7-android-api-23-rooted.apk',
      type: 'APK',
      os: 'Android 6 (API 23)',
      version: '1.0.7-android',
      arch: 'arm64',
      releasedAt: '2024-07-27T00:00:00Z',
      downloadUrl:
        'https://cdn.signgrid.io/download/signage-1.0.7-android-api-23-rooted.apk',
    },
    {
      id: '5',
      name: 'signage-1.0.8-linux-amd64.zip',
      type: 'ZIP',
      os: 'Linux (amd64)',
      version: '1.0.8-linux',
      arch: 'amd64',
      releasedAt: '2024-08-20T00:00:00Z',
      downloadUrl:
        'https://cdn.signgrid.io/download/signage-1.0.8-linux-amd64.zip',
    },
    {
      id: '6',
      name: 'signage-1.0.8-linux-arm64.zip',
      type: 'ZIP',
      os: 'Linux (arm64)',
      version: '1.0.8-linux',
      arch: 'arm64',
      releasedAt: '2024-08-20T00:00:00Z',
      downloadUrl:
        'https://cdn.signgrid.io/download/signage-1.0.8-linux-arm64.zip',
    },
    {
      id: '7',
      name: 'signage-1.0.8-macos.zip',
      type: 'ZIP',
      os: 'macOS',
      version: '1.0.8-macos',
      arch: 'arm64',
      releasedAt: '2024-08-20T00:00:00Z',
      downloadUrl: 'https://cdn.signgrid.io/download/signage-1.0.8-macos.zip',
    },
  ]
  return (
    <>
      <Box sx={{}}>
        <Box
          sx={{
            padding: 3,
          }}
        >
          <PageHeader>
            <PageHeader.TitleArea>
              <PageHeader.Title as='h2'>Downloads</PageHeader.Title>
            </PageHeader.TitleArea>
          </PageHeader>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
            width: '100%',
            height: '100%',
            marginTop: 4,
            // backgroundColor: 'canvas.default',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              // backgroundColor: 'green'
            }}
          >
            {/* <EmptyState /> */}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <Table.Container>
                <Table.Title as='h2' id='downloads'>
                  Releases
                </Table.Title>
                <Table.Subtitle as='p' id='downloads-subtitle'>
                  {/* A subtitle could appear here to give extra context to the data. */}
                </Table.Subtitle>
                <DataTable
                  aria-labelledby='downloads'
                  aria-describedby='downloads-subtitle'
                  data={data}
                  columns={[
                    {
                      header: 'File',
                      field: 'name',
                      rowHeader: true,
                    },
                    {
                      header: 'Type',
                      field: 'type',
                      renderCell: row => {
                        return <Label>{row.type}</Label>
                      },
                    },
                    {
                      header: 'OS',
                      field: 'os',
                      renderCell: row => {
                        return <Label>{row.os}</Label>
                      },
                    },
                    {
                      header: 'Version',
                      field: 'version',
                    },
                    {
                      header: 'Arch',
                      field: 'arch',
                      renderCell: row => {
                        return <Label>{row.arch}</Label>
                      },
                    },
                    {
                      header: 'Released',
                      field: 'releasedAt',
                      renderCell: row => {
                        return <RelativeTime date={new Date(row.releasedAt)} />
                      },
                    },
                    {
                      header: 'Download',
                      field: 'downloadUrl',
                      renderCell: row => {
                        return (
                          <IconButton
                            as='a'
                            href={!row.disabled ? row.downloadUrl : '#'}
                            variant='invisible'
                            icon={Icons.Download}
                          ></IconButton>
                        )
                      },
                    },
                  ]}
                />
              </Table.Container>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                // backgroundColor: "danger.emphasis",
                alignItems: 'start',
                mt: 4,
                gap: 2,
              }}
            >
              <Button disabled about='' href='' variant='invisible'>
                Changelog
              </Button>

              <Timeline>
                <Timeline.Item>
                  <Timeline.Badge>
                    <Icons.Zip />
                  </Timeline.Badge>
                  <Timeline.Body>
                    1.0.8-linux - first release for linux amd64 and arm64. Known
                    issues: additional configuration required for autostart,
                    hide mouse cursor and screen blanking. (raspberry pi)
                  </Timeline.Body>
                </Timeline.Item>

                <Timeline.Item>
                  <Timeline.Badge>
                    <Icons.Zip />
                  </Timeline.Badge>
                  <Timeline.Body>
                    1.0.8-android - fixed a bug in apps rendering causing freeze
                  </Timeline.Body>
                </Timeline.Item>

                <Timeline.Item>
                  <Timeline.Badge>
                    <Icons.Zip />
                  </Timeline.Badge>
                  <Timeline.Body>
                    1.0.7-android - silent update on rooted devices additional
                    status information message
                  </Timeline.Body>
                </Timeline.Item>

                <Timeline.Item>
                  <Timeline.Badge>
                    <Icons.Zip />
                  </Timeline.Badge>
                  <Timeline.Body>
                    1.0.6-android - proof of play api released
                  </Timeline.Body>
                </Timeline.Item>

                <Timeline.Item>
                  <Timeline.Badge>
                    <Icons.Zip />
                  </Timeline.Badge>
                  <Timeline.Body>
                    1.0.5-android - added empty-storage command, download file
                    integrity check and fixed a bug impacting slow internet
                    connection devices
                  </Timeline.Body>
                </Timeline.Item>
              </Timeline>
              <LinkButton
                href='https://docs.signgrid.io/devices/android-app-installation'
                variant='invisible'
              >
                Android install guide
              </LinkButton>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  marginTop: 4,
                  gap: 2,
                }}
              >
                <Text fontSize={2} color='fg.default' fontWeight='bold'>
                  Linux guide amd64
                </Text>
                <Box
                  sx={{
                    display: 'flex',
                    backgroundColor: 'canvas.default',
                    borderRadius: 2,
                    border: '1px solid',
                    padding: 4,
                    overflow: 'auto',
                    borderColor: 'border.default',
                    flexDirection: 'column',
                    color: 'fg.muted',
                    fontFamily: 'mono',
                  }}
                >
                  <Text>sudo apt update</Text>
                  <Text>sudo apt-get install -y libmpv-dev</Text>
                  <Text>mkdir ~/signgrid-signage</Text>
                  <Text>cd ~/signgrid-signage</Text>
                  <Text>
                    wget
                    https://cdn.signgrid.io/download/signage-1.0.8-linux-amd64.zip
                  </Text>
                  <Text>unzip signage-1.0.8-linux-amd64.zip</Text>
                  <Text>chmod +x signage </Text>
                  <Text>./signage</Text>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  marginTop: 4,
                  gap: 2,
                }}
              >
                <Text fontSize={2} color='fg.default' fontWeight='bold'>
                  Linux guide arm64 (raspberry pi)
                </Text>
                <Box
                  sx={{
                    display: 'flex',
                    backgroundColor: 'canvas.default',
                    borderRadius: 2,
                    border: '1px solid',
                    padding: 4,
                    overflow: 'auto',
                    borderColor: 'border.default',
                    flexDirection: 'column',
                    color: 'fg.muted',
                    fontFamily: 'mono',
                  }}
                >
                  <Text>sudo apt update</Text>
                  <Text>sudo apt-get install -y libmpv-dev</Text>
                  <Text>mkdir ~/signgrid-signage</Text>
                  <Text>cd ~/signgrid-signage</Text>
                  <Text>
                    wget
                    https://cdn.signgrid.io/download/signage-1.0.8-linux-arm64.zip
                  </Text>
                  <Text>unzip signage-1.0.8-linux-arm64.zip</Text>
                  <Text>chmod +x signage </Text>
                  <Text>./signage</Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
